import { ApiConfig, ApiResult } from "common/models";
import { HttpClient } from "./http-client.service";
import { DisplayCondition } from "common/models/action-cell-renderer.model";

export class GridService extends HttpClient {
    public static async getTableData(url:any): Promise<ApiResult<any> | undefined> {
        const apiConfig = new ApiConfig(`${url}`, 'GET');
    
        return await this.invokeApi<any>(apiConfig);
    }
    public static async getServerSidePaginationData(urlInfo:string,currentPageNumber:number,pageSize:number,sortOrder?:number,sortColumn?:string,filterstring?:string,searchFilter?:string,additionalQueryString?:string):Promise<ApiResult<any> | undefined> {
        let queryString = `PageNumber=${currentPageNumber}&PageSize=${pageSize}`;
        if(sortColumn&&sortOrder){
            queryString =  queryString+`&SortOrder=${sortOrder}&SortColumn=${sortColumn}`;
        }
        if(filterstring){
            queryString = queryString+filterstring;
        }
        if(searchFilter){
            queryString =  queryString+`&Filters=${searchFilter}`;
        }
        if(additionalQueryString){
            queryString =  queryString+`${additionalQueryString}`;
        }
        let apiConfig :ApiConfig<any> = {
            url: urlInfo,
            method:'GET',
            queryString: encodeURIComponent(queryString),
        }
        return await this.invokeApi<any>(apiConfig);
    }

    public static evaluateExpression(displayConditions:DisplayCondition[],params:any):boolean{
        let evaluateExpressionReturn:boolean=true;
        let evaluateExpression:boolean=true;
        for(let x=0;x<displayConditions.length;x++){
        
        let displayCondition = displayConditions[x];
        let conditions = displayCondition.conditions;
        let groupJoinCondition = displayCondition?.groupJoinCondition;
        let conditionExpression:any;
        for(let i=0;i<conditions?.length;i++)
        {
           let condition = conditions[i];
           let fieldNames=condition.fieldName.split(".");
           fieldNames.forEach((field:string,index)=>{
              if(index==0)
              conditionExpression = params[field];
              else
              conditionExpression = conditionExpression[field];
           })
          
           if(condition?.parseDataFunction=="parseFloat"){
            conditionExpression =  parseFloat(conditionExpression);
           }
           if(condition?.parseDataFunction=="parseInt"){
            conditionExpression =  parseInt(conditionExpression);
           }
           let conditionValue = condition?.value;
           let expresionResult:boolean=true;
      
             if(condition?.matchType=="=="){
             expresionResult = (conditionExpression==conditionValue);
           }
           else if(condition?.matchType=="!="){
             expresionResult = (conditionExpression!=conditionValue);
           }
           else if(condition?.matchType=="!"){
            expresionResult = !conditionExpression;
           }
           else if(condition?.matchType==""){
             expresionResult = conditionExpression;
           }
          
          
          if(condition?.joinCondition=="&&"){
             evaluateExpression = evaluateExpression && expresionResult;
          }
          if(condition?.joinCondition=="||"){
           evaluateExpression = evaluateExpression || expresionResult;
          }
          if(condition?.joinCondition==""){
           evaluateExpression = expresionResult;
          }
      }
      if(groupJoinCondition=="&&"){
        evaluateExpressionReturn = evaluateExpressionReturn && evaluateExpression;
       }
       else if(groupJoinCondition=="||"){
        evaluateExpressionReturn = evaluateExpressionReturn || evaluateExpression;
      }
       else if(groupJoinCondition==""){
        evaluateExpressionReturn = evaluateExpression;
      }
    
      }
      return evaluateExpressionReturn;
    }
}
